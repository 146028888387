import { useCallback } from 'react';
import { usePageData } from 'hooks';
import TagManager from 'react-gtm-module';
import isTrackingAllowed from 'helpers/consent';
import { getCmsUrl } from 'helpers';

export default function useGenerateLead () {
  const { data, path, template } = usePageData();

  const handleGenerateLead = useCallback((leadData) => {
    if (!isTrackingAllowed()) return;

    const { id, name, email, service, subject } = leadData;

    const additional = {}
    if (template === 'camper-page') {
      const product = data?.content || null
      additional.product = {
        id: product?.id || null,
        name: product?.name || null,
        price: parseFloat(product?.sale_price && product?.sale_price > 0 ? product?.sale_price : product?.price) || null,
        category: 'Matkasuvilad',
      }
    } else if (template === 'accessory-page') {
      const product = data?.content?.find(section => section.layout === 'details')?.attributes || null
      additional.product = {
        id: product?.id,
        name: product?.name,
        price: product?.price,
        category: product?.categories?.[0]?.name || null,
      }
    }

    if (!id || !name || !email) return;

    const eventId = `generate_lead-${id}-${Date.now()}`;

    TagManager.dataLayer({
      dataLayer: {
        event: 'generate_lead',
        event_id: eventId,
        lead: {
          lead_id: id,
          name: name,
          email: email,
          service: service || null,
          subject: subject || null,
          path: path || null,
          ...additional
        },
      },
    });

    window.fbq && window.fbq('track', 'Lead', {
      content_ids: [id],
      content_type: 'lead',
      event_id: eventId,
    });

    // Conversions API
    fetch(`${getCmsUrl()}/api/meta/conversions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event_name: 'Lead',
        event_time: Math.floor(Date.now() / 1000),
        action_source: 'website',
        event_id: eventId,
        event_source_url: window.location.href,
        user_data: {
          em: email,
          fbc: document.cookie.match(/_fbc=([^;]+)/)?.[1] || null,
          fbp: document.cookie.match(/_fbp=([^;]+)/)?.[1] || null,
        },
        custom_data: {
          content_name: name,
        },
      }),
    }).then(response => {
      if (!response.ok) {
        console.error('Meta Conversions API request failed:', response);
      }
    });
  }, [data, path, template]);

  return handleGenerateLead;
}
