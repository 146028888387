import { usePageData } from "hooks";
import { setLocale } from "store/I18nSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getNextLocale } from "./helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LanguageSwitcher = (props) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { className } = props;
  const page = usePageData();
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    i18n.changeLanguage(page.locale);
    dispatch(setLocale(page.locale));
  }, [dispatch, page.locale, i18n]);

  if (!Object.keys(page).length) {
    return null;
  }

  const nextLocale = getNextLocale(page);
  if (page?.links?.[nextLocale.locale]) {
    nextLocale.path = page.links[nextLocale.locale];
  }

  const classes = `language-switcher${className ? " " + className : ""}`;
  const scope = searchParams.get("scopes") ? `&scopes=${searchParams.get("scopes")}` : "";
  const query = searchParams.get("query") ? `?query=${searchParams.get("query")}${scope}` : "";

  const changeLocale = () => {
    i18n.changeLanguage(nextLocale.locale, () => {
      dispatch(setLocale(nextLocale.locale));
      let path = nextLocale.path;
      if (path.includes(':id')) {
        path = path.replace(':id', '');
      }
      navigate(path + query);
    });
  };

  return (
    <button {...props} className={classes} onClick={changeLocale}>
      {nextLocale.locale.toUpperCase()}
    </button>
  );
};

export default LanguageSwitcher;
