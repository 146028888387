export default function FooterColumn (props) {
  return <div className={`footer-column${props.className ? ` ${props.className}` : ''}`}>
    {props.title && <div className="footer-column__title">{props.title}</div>}
    {props.items && <ul className={`footer-column__list${props.smallGap ? ' footer-column__list--tight' : ''}`}>
      {props.items.map(item => <li key={item.key} className="footer-column__list-item">
        {item.attributes.caption && <span className="footer-column__list-item-caption">{item.attributes.caption}</span>}
      </li>)}
    </ul>}
  </div>
}
