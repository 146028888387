import { useEffect, useState } from 'react';
import usePageData from './usePageData';
import TagManager from 'react-gtm-module';
import { debounce } from 'lodash';
import isTrackingAllowed from 'helpers/consent';
// import { getCmsUrl } from 'helpers';

export default function usePageView () {
  const [previousPage, setPreviousPage] = useState(null);
  const page = usePageData();

  const title = page?.seo?.title || page?.name || window.title
  const url = window.location.href;

  const sendPageView = debounce(() => {
    if (isTrackingAllowed()) {
      // const eventId = `page_view-${order.id}-${Date.now()}`;

      TagManager.dataLayer({
        dataLayer: {
          event: 'page_view',
          page: {
            title: title,
            url: url,
          }
        },
      });

      // window.fbq && window.fbq('track', 'Purchase', {
      //   content_ids: order.lines.map(item => item.id),
      //   content_type: 'product',
      //   value: parseFloat(order.total),
      //   currency: 'EUR',
      //   event_id: eventId,
      //   email: order.client.email,
      //   phone: phone,
      // });

      // // Conversions API
      // fetch(`${getCmsUrl()}/api/meta/conversions`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     event_name: 'Purchase',
      //     event_time: Math.floor(Date.now() / 1000),
      //     event_id: eventId,
      //     user_data: {
      //       email: order.client.email,
      //       phone: phone,
      //       fbc: document.cookie.match(/_fbc=([^;]+)/)?.[1] || null,
      //       fbp: document.cookie.match(/_fbp=([^;]+)/)?.[1] || null,
      //     },
      //     custom_data: {
      //       transaction_id: order.id,
      //       value: parseFloat(order.total),
      //       currency: 'EUR',
      //       items: formattedItems,
      //     },
      //   }),
      // }).then(response => {
      //   if (!response.ok) {
      //     console.error('Meta Conversions API request failed:', response);
      //   }
      // });
    }

    setPreviousPage(url);
  }, 500);

  useEffect(() => {
    if (!title || previousPage === url) return;
    sendPageView();

    return () => sendPageView.cancel();
  }, [title, previousPage, sendPageView, url]);
}
