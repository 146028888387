import { createSlice } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import isTrackingAllowed from 'helpers/consent';
import { getCmsUrl } from 'helpers';

const initialState = JSON.parse(localStorage.getItem('cart')) || {
  cartIsOpen: false,
  orderId: null,
  products: [],
  totalQuantity: 0,
  totalPrice: 0,
  totalPriceWithShipping: 0,
  shippingPrice: 0,
  checkout: {
    information: {},
    shipping: {},
    payment: {}
  },
  paymentMethods: [],
  shippingMethods: []
};

initialState.cartIsOpen = false;

const addToDataLayer = (product) => {
  if (!isTrackingAllowed()) return;

  const { id, name, category, price, quantity } = product;

  if (!id || !name || !price || !quantity) return;

  const eventId = `add_to_cart-${Date.now()}`;

  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_cart',
      event_id: eventId,
      ecommerce: {
        value: parseFloat(price),
        currency: 'EUR',
        items: [
          {
            item_id: id,
            item_name: name,
            price: parseFloat(price),
            quantity: quantity,
            item_category: category || null,
          }
        ]
      }
    }
  })

  window.fbq && window.fbq('track', 'AddToCart', {
    content_ids: [id],
    content_type: 'product',
    value: parseFloat(price),
    currency: 'EUR',
    event_id: eventId,
  });

  // Conversions API
  fetch(`${getCmsUrl()}/api/meta/conversions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      event_name: 'AddToCart',
      event_time: Math.floor(Date.now() / 1000),
      action_source: 'website',
      event_id: eventId,
      event_source_url: window.location.href,
      user_data: {
        fbc: document.cookie.match(/_fbc=([^;]+)/)?.[1] || null,
        fbp: document.cookie.match(/_fbp=([^;]+)/)?.[1] || null,
      },
      custom_data: {
        currency: 'EUR',
        value: parseFloat(price),
        content_type: 'product',
        contents: [
          {
            id: id,
            quantity: quantity,
          }
        ],
        content_name: name,
        content_category: category,
      },
    }),
  }).then(response => {
    if (!response.ok) {
      console.error('Meta Conversions API request failed:', response);
    }
  });
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct (state, action) {
      if (action.payload.quantity < 1 || action.payload.stock_quantity < action.payload.quantity) return;

      const existingProductIndex = state.products.findIndex(
        (product) => product.id === action.payload.id
      );

      if (existingProductIndex !== -1) {
        const newQuantity = state.products[existingProductIndex].quantity + action.payload.quantity;
        state.products[existingProductIndex].quantity += Math.min(newQuantity, action.payload.stock_quantity);
      } else {
        state.products.push(action.payload);
        addToDataLayer(action.payload)
      }

      state.totalPrice += action.payload.quantity * (action.payload.price ?? action.payload.oldPrice)
      state.totalPriceWithShipping = state.totalPrice + state.shippingPrice
      state.totalQuantity += action.payload.quantity;

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    setCartIsOpen (state, action) {
      state.cartIsOpen = action.payload;
    },

    increaseProductCount (state, action) {
      const productId = action.payload;
      const productToUpdate = state.products.find((product) => product.id === productId);

      if (productToUpdate) {
        productToUpdate.quantity++;
      }

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },

    decreaseProductCount(state, action) {
      const productId = action.payload;
      const productToUpdate = state.products.find((product) => product.id === productId);

      if (productToUpdate && productToUpdate.quantity > 1) {
        productToUpdate.quantity -= 1;
      } else if (productToUpdate && productToUpdate.quantity < 2) {
        const productIndex = state.products.findIndex((product) => product.id === productId);
        state.products.splice(productIndex, 1);
      }

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },

    removeProduct(state, action) {
      const productIdToRemove = action.payload;
      const productIndexToRemove = state.products.findIndex(
        (product) => product.id === productIdToRemove
      );

      if (productIndexToRemove !== -1) {
        state.totalPriceWithShipping = state.totalPrice + state.shippingPrice
        state.products.splice(productIndexToRemove, 1);
      }

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    updateCheckoutDetails(state, action) {
      const {type, details} = action.payload;
      if (type === 'information') {
        state.checkout.information = { ...state.checkout.information, ...details };
      } else if (type === 'shipping') {
        state.checkout.shipping = { ...state.checkout.shipping, ...details };
      } else if (type === 'payment') {
        state.checkout.payment = { ...state.checkout.payment, ...details };
      }

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    updateShippingPrice(state, action) {
      state.shippingPrice = action.payload
      state.totalPriceWithShipping = state.totalPrice + action.payload

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    updatePickupAddress(state, action) {
      state.checkout.shipping.pickupAddress = action.payload;

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    updateStockQuantity(state, action) {
      const { id, newStockQuantities } = action.payload;
      const productToUpdate = state.products.find((product) => product.id === id);
      if (productToUpdate) {
        productToUpdate.stock_quantity = newStockQuantities;
      }

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    setOrderId(state, action) {
      state.orderId = action.payload;

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    addPaymentMethods(state, action) {
      state.paymentMethods = action.payload;

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    addShippingMethods(state, action) {
      state.shippingMethods = action.payload;

      calculateTotals(state)
      saveCartToLocalStorage(state)
    },
    clearCartStore(state){
      state.orderId = null;
      state.products = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      state.totalPriceWithShipping = 0;
      state.shippingPrice = 0;
      state.checkout = {
        information: {},
        shipping: [],
        payment: []
      };
      state.paymentMethods = [];
      state.shippingMethods = [];

      calculateTotals(state);
      saveCartToLocalStorage(state);
    },
    setCartStore(state, action) {
      localStorage.removeItem('cart');
      action.payload.cartIsOpen = false
      return { ...state, ...action.payload };
    },
  },
});

const selectProductById = (productId) => (state) =>
  state.cart.products.find((product) => product.id === productId);

const saveCartToLocalStorage = (state) => {
  localStorage.setItem('cart', JSON.stringify(state));
};

const calculateTotals = (cart) => {
  cart.totalPrice = cart.products.reduce((acc, product) => {
    return acc + (product.price ?? product.oldPrice) * product.quantity;
  }, 0);

  cart.totalPriceWithShipping = cart.totalPrice + cart.shippingPrice;

  cart.totalQuantity = cart.products.reduce((acc, product) => {
    return acc + product.quantity;
  }, 0);

  return cart;
};

export const {
  addProduct,
  setCartIsOpen,
  increaseProductCount,
  decreaseProductCount,
  removeProduct,
  updateCheckoutDetails,
  updateShippingPrice,
  updatePickupAddress,
  updateStockQuantity,
  setOrderId,
  addPaymentMethods,
  addShippingMethods,
  setCartStore,
  clearCartStore
} = cartSlice.actions;


export {selectProductById};

export default cartSlice.reducer;
