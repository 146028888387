import { Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadInitialContent} from 'store/GlobalContentSlice';
import classNames from "classnames";

import './style/entry.scss';
import {Pages} from './pages';

import ErrorPage from "./pages/ErrorPage";
import { NotFoundPage } from "pages";
import useResetErrorOnRouteChange from 'hooks/useResetErrorOnRouteChange';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-T92CMVH'
}

const setConsentDefaults = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag () {
    window.dataLayer.push(arguments);
  }
  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    wait_for_update: 2000,
  });
  gtag('set', 'ads_data_redaction', true);
  gtag('set', 'url_passthrough', true);
};

export default function App () {
  const dispatch = useDispatch();
  useResetErrorOnRouteChange();

  const { activePage } = useSelector(state => state.globalContent)
  let className = classNames('App', (activePage && 'App_' + activePage));

  const loading = useSelector(state => state.globalContent.loading);
  const error = useSelector(state => state.globalContent.error);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    setConsentDefaults();
    dispatch(loadInitialContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <Suspense fallback={<div className="loader">Loading...</div>}>
        {error === 'Bad request' && <ErrorPage />}
        {error === 'Not found' && <NotFoundPage />}
        {!error && <Pages/>}
      </Suspense>
      {loading && <div className="loader">Loading...</div>}
    </div>
  );
}
