import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from "./store";
import App from './App';

import "./i18n";
import ErrorBoundary from 'components/ErrorBoundary';

// ReactDOM.hydrateRoot(
//   document,
//   <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <ErrorBoundary>
//           <App />
//         </ErrorBoundary>
//       </BrowserRouter>
//      </Provider>
//   </React.StrictMode>
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
